<template>
    <div class="scene-section">
        <Header :showback="true">
            <template slot="title">{{recordList.title}}</template>
        </Header>
        <div v-html="recordList.content" style="color: #fff;padding: 16px 16px 0;">

        </div>
        <div>
            <!--<h2 style="padding: 16px 16px 0;color: #fff;">请选择投票用户：</h2>-->
            <div style="padding: 16px 16px 0;">
                <van-radio-group v-model="radio" >
                    <van-cell-group>
                        <template v-for="(item, index) in answerList">
                            <van-cell :title="item.name+'('+item.vote_num+')'" clickable  @click="radio = item.address_id">
                                <div style="padding: 0 8px;text-align: left;">
                                    <div style="color: #fff;">
                                        候选地址：{{item.vote_address}}
                                    </div>
                                    <div style="color: #ccc;font-size: 14px;" v-html="item.content"></div>
                                </div>
                                <van-radio slot="right-icon" :name="item.address_id" />
                            </van-cell>
                        </template>
                    </van-cell-group>
                </van-radio-group>
            </div>

            <div class="submit">
                <div class="submitbtn" v-if="recordList.status==0" style="background: #ccc;">未开始</div>
                <div class="submitbtn" v-if="recordList.status==1" @click="showpwd = true">提交</div>
                <div class="submitbtn" v-if="recordList.status==2" style="background: #F0851A;color: #fff;">已结束</div>
            </div>
        </div>
        <van-dialog
                v-model="showpwd"
                title="投票"
                @cancel="pwdShow"
                @confirm="clickSubmit"
                show-cancel-button
        >
            <van-field
                    type="password"
                    v-model="password"
                    name="安全密码"
                    label="安全密码"
                    placeholder="请输入安全密码"
            ></van-field>
        </van-dialog>
    </div>

</template>

<script>
    import Header from "../../components/header.vue";
    import {Toast} from "vant";
    import {voteInfo, submitVote} from "@/request/api";
    import $ from "jquery";

    export default {
        name: "",
        components: {
            Header,
        },
        data() {
            return {
                showpwd: false,
                password: "",
                time: 10 * 1000,
                showIntroduce: true,
                refreshing: false,
                loading: false,
                finished: false,
                recordList: {},
                checked: [],
                address: "",
                success: [],
                showResult: false,
                score: 0,
                answerList: [

                ],
                radio: 0,
                activeIcon: 'https://img.yzcdn.cn/vant/user-active.png',
                inactiveIcon: 'https://img.yzcdn.cn/vant/user-inactive.png'
            };
        },
        activated() {
            this.showIntroduce = true;
            this.address = sessionStorage.getItem("address");
            this.checked = [];
            this.success = [];
            if (this.address && sessionStorage.getItem(this.address)) {
                this.getDataList();
            }
        },
        methods: {
            pwdShow() {
                this.showpwd = false;
            },
            finish() {
                this.showIntroduce = false;
            },
            closeShowResult() {
                this.showResult = false;
            },
            clickSubmit() {
                let _this = this;
                if (!this.address || !sessionStorage.getItem(this.address)) {
                    Toast("请使用币安智能链链接");
                    return false;
                }
                if (this.radio==0) {
                    Toast("请选择投票人");
                    return;
                }
                if (!this.password) {
                    Toast("请输入安全密码");
                    return;
                }
                const params = {
                    address: this.address,
                    pay_code: this.password,
                    address_id:this.radio,

                };
                submitVote(params).then((res) => {
                    if (res.code == 200) {
                        Toast("参与成功");
                        this.getDataList();
                    } else {
                        Toast(res.msg);
                    }
                });
            },
            changeTi(index, cate_id, event) {
                this.$refs.swipe.next();
                this.checked[index] = cate_id;
                let toggle = event.currentTarget;
                $(toggle).siblings().removeClass("question_tab_img_active");
                $(toggle)
                    .parent()
                    .siblings()
                    .children()
                    .removeClass("question_tab_img_active");
                $(toggle).addClass("question_tab_img_active");
                console.log(this.checked);
                console.log(cate_id);
            },
            getDataList() {
                const params = {
                    address: this.address,
                };
                voteInfo(params).then((res) => {
                    this.recordList = res.data;
                    this.answerList = res.data.address_list;
                    console.log(this.answerList)
                });
            },
        },
    };
</script>
<style scoped lang="scss">
    .van-cell__title{
        /*-webkit-flex:none;*/
        -webkit-box-flex: 1;
        -webkit-flex: 1;
    }
    .van-cell__value{
        -webkit-box-flex: 3;
        -webkit-flex: 3;
    }
    .van-cell-group{
        border-radius: 8px;
        background: linear-gradient(135.4deg, #4cca81 0%, #46b779 100%);
    }
    .van-hairline--top-bottom::after, .van-hairline-unset--top-bottom::after{
        border: none;
    }
    .van-cell{
        background: none;
    }
    ::v-deep .van-count-down {
        font-size: 30px;
        color: white;
        text-align: center;
    }

    .systemcon {
        ::v-deep .van-popup {
            border-radius: 6px;
        }
    }

    .introduce {
        width: 700px;
        height: 100%;

        background-color: #078538;
        .content {
            padding: 24px;
            color: white;
            font-weight: bold;
            .title {
                text-align: center;
                font-size: 38px;
            }
            .introducetxt {
                margin-top: 15px;
                font-size: 30px;
                line-height: 50px;
            }
        }
    }

    .swpipe {
        height: 900px;
        background: transparent;
    }

    .scene-section {
        background: #0c1913;
        min-height: 100vh;
    }

    .demo-radio .van-doc-demo-block__title {
        margin-top: -8px;
    }

    .question_title {
        font-weight: bold;
        color: #27cc7f;
    }

    .question_tab {
        display: flex;
        flex-wrap: wrap;
        padding: 2% 5%;
        justify-content: space-around;
    }

    .question_tab_img {
        width: 35%;
        margin-bottom: 5%;
    }

    .question_tab_img_active {
        border: 8px solid #fff;
    }

    .van-doc-demo-block__title {
        margin: 0;
        padding: 16px 16px 16px;
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    .submit {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        .submitbtn {
            width: 320px;
            height: 88px;
            border-radius: 16px;
            opacity: 1;
            background: rgba(39, 204, 127, 1);
            color: rgba(32, 40, 35, 1);
            font-size: 36px;
            font-weight: 700;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 88px;
            margin-bottom: 50px;
        }
    }
</style>
